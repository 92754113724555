import React, { Component } from "react";
export default class AdditionalFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
    };
  }

  componentDidUpdate(prevProps) {
    // Minimize Filter component if an ecosystem is not selected
    // if (!this.props.showAdditionalFilters && this.state.isToggleOn) {
    //     this.setState({
    //         isToggleOn: false
    //     });
    // }

    // Reset checkboxes upon change of Ecosystem
    if (
      prevProps.activeEcosystem !== this.props.activeEcosystem ||
      prevProps.activeSubEcosystem !== this.props.activeSubEcosystem ||
      this.props.activeMunicipality
    ) {
      document
        .querySelectorAll('.additionalFilters input[type="checkbox"]:checked')
        .forEach((checkbox) => (checkbox.checked = false));
    }
  }

  // This function parses the available contributing ecosystems into checkboxes
  parseOptions = (options) => {
    let parsedOptions = options.map((option) => {
      const systemName =
        "major-waterways-" +
        option.replace(/[,.]/g, "").replace(/[ ]/g, "-").toLowerCase();
      return (
        <div key={systemName}>
          <input type="checkbox" id={systemName} onChange={this.handleChange} />
          <label htmlFor={systemName}>{option}</label>
        </div>
      );
    });
    parsedOptions.push(
      <div key="any">
        <input
          type="checkbox"
          id="major-waterways-any"
          onChange={this.handleChange}
        />
        <label htmlFor="major-waterways-any">Any</label>
      </div>,
    );
    return parsedOptions;
  };

  // called by handleChange after event listeners
  returnCheckedboxes = () => {
    let checkedCheckboxes = [];
    const checkboxes = document.querySelectorAll(
      '.additionalFilters input[type="checkbox"]:checked',
    );
    checkboxes.forEach((element) => {
      checkedCheckboxes.push(element.id);
    });
    this.props.handleAdditionalFilters(checkedCheckboxes);
  };

  // This function handles the different checkboxes being activated
  handleChange = () => {
    const majorWaterways = Array.from(
      document.querySelectorAll('.additionalFilters [id^="major-waterways"]'),
    ).filter((element) => element.id !== "major-waterways-any");
    const majorWaterwaysAny = document.querySelector(
      ".additionalFilters #major-waterways-any",
    );

    majorWaterways.forEach((element) => {
      element.addEventListener("change", () => {
        if (element.checked) {
          majorWaterwaysAny.checked = false;
        }
      });
    });

    majorWaterwaysAny.addEventListener("change", () => {
      if (majorWaterwaysAny.checked) {
        majorWaterways.forEach((element) => {
          element.checked = false;
        });
      }
    });

    // timeout to ensure event listeners finish
    setTimeout(this.returnCheckedboxes, 0);
  };

  handleTray = () => {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <div
          className={
            this.props.showAdditionalFilters
              ? "additionalFiltersWrapper show"
              : "additionalFiltersWrapper hide"
          }
        >
          <div
            className={
              this.state.isToggleOn
                ? "additionalFiltersContainer onCanvas"
                : "additionalFiltersContainer offCanvas"
            }
          >
            <div className="tabOffCanvas" onClick={this.handleTray}>
              <div className="arrow" />
            </div>
            <div className="additionalFilters">
              <div className="tab" onClick={this.handleTray}>
                <div className="arrow" />
              </div>
              <div className="closeMe" onClick={this.handleTray} />
              <div className="additionalFiltersBody">
                <p className="additionalFiltersIntro">
                  <em>
                    Use the checkboxes below to filter the municipalities listed
                    in the sidebar. You may use more than one filter. The
                    content of the sidebar will change automatically as you
                    toggle or untoggle the filters.
                  </em>
                </p>
                <fieldset>
                  <legend>
                    Municipality has initiative lawmaking by the People?
                  </legend>
                  <input
                    type="checkbox"
                    id="initiative-lawmaking-by-people-yes"
                    name="initiative-lawmaking-by-people-yes"
                    value="initiative-lawmaking-by-people-yes"
                    label="initiative-lawmaking-by-people-yes"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="initiative-lawmaking-by-people-yes">
                    Yes
                  </label>
                  <input
                    type="checkbox"
                    id="initiative-lawmaking-by-people-yes-conditionally"
                    name="initiative-lawmaking-by-people-yes-conditionally"
                    value="initiative-lawmaking-by-people-yes-conditionally"
                    label="initiative-lawmaking-by-people-yes-conditionally"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="initiative-lawmaking-by-people-yes-conditionally">
                    Yes, conditionally
                  </label>
                </fieldset>

                <fieldset>
                  <legend>Municipality population:</legend>
                  <input
                    type="checkbox"
                    id="municipality-population-5000"
                    name="municipality-population-5000"
                    value="municipality-population-5000"
                    label="municipality-population-5000"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="municipality-population-5000">
                    Under 5,000
                  </label>
                  <input
                    type="checkbox"
                    id="municipality-population-10000"
                    name="municipality-population-10000"
                    value="municipality-population-10000"
                    label="municipality-population-10000"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="municipality-population-10000">
                    Between 5,000 and 10,000
                  </label>
                  <input
                    type="checkbox"
                    id="municipality-population-over"
                    name="municipality-population-over"
                    value="municipality-population-over"
                    label="municipality-population-over"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="municipality-population-over">
                    Over 10,000
                  </label>
                </fieldset>

                <fieldset>
                  <legend>
                    Democratic registration outnumbers Republican?
                  </legend>
                  <input
                    type="checkbox"
                    id="outnumber"
                    name="outnumber"
                    value="outnumber"
                    label="outnumber"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="outnumber">Yes</label>
                </fieldset>

                <fieldset>
                  <legend>
                    Municipality is on the {this.props.activeEcosystem}?
                  </legend>
                  <input
                    type="checkbox"
                    id="ontheriver"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="ontheriver">Yes</label>
                </fieldset>
                <fieldset>
                  <legend>
                    Municipality is on one or more particular major waterways
                    that feed the {this.props.activeEcosystem}?
                  </legend>
                  {this.parseOptions(this.props.subEcosystemOptions)}
                </fieldset>
                <fieldset>
                  <legend>
                    Environmental organization active in municipality?
                  </legend>
                  <input
                    type="checkbox"
                    id="environmental-active"
                    name="environmental-active"
                    value="environmental-active"
                    label="environmental-active"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="environmental-active">Yes</label>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
