import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import csv from "./assets/images/csv.png";
import pin from "./assets/images/pin.png";

const Story = (props) => {
  const { story, storyClass } = props;

  // Conditional show/hide state set up
  const [isExtraContentShown, setIsExtraContentShown] = useState(false);

  const prevNameRef = useRef();
  useEffect(() => {
    prevNameRef.current = story["Name of Municipality"];
  });

  if (
    prevNameRef.current &&
    story["Name of Municipality"] !== prevNameRef.current &&
    isExtraContentShown
  ) {
    setIsExtraContentShown(!isExtraContentShown);
  }

  // Handler function for showing and hiding extra content in cards
  const buttonHandler = () => {
    setIsExtraContentShown(!isExtraContentShown);
  };

  const compareDemRep = (dem, rep) => {
    if (parseInt(dem) > parseInt(rep)) {
      return "✔️ Democratic voters outnumber Republican voters.";
    } else if (parseInt(rep) > parseInt(dem)) {
      return "❌ Democratic voters do not outnumber Republican voters.";
    } else {
      return;
    }
  };

  const distillLawmaking = (lawmaking) => {
    if (lawmaking.includes("Yes, conditionally")) {
      return "✔️ Yes, conditionally";
    } else if (lawmaking.includes("Yes")) {
      return "✔️ Yes";
    } else if (
      lawmaking.includes("Maybe") ||
      lawmaking.includes("could have it")
    ) {
      return "✔️ Yes, conditionally";
    } else {
      return "❌ No";
    }
  };

  const popEstimate = (pop) => {
    if (pop < 5000) {
      return "Under 5,000";
    } else if (pop < 10000) {
      return "Under 10,000";
    } else if (pop > 10000) {
      return "Over 10,000";
    } else {
      return;
    }
  };

  const tributaries = () => {
    let arr = [];
    if (story[props.tributaryKey]) {
      arr.push(story[props.tributaryKey]);
    }

    if (story[props.waterSourceKey]) {
      arr.push(story[props.waterSourceKey]);
    }

    return arr.join(", ");
  };

  // Assign unknown municipality name if one is not given
  let storyTitle = "";
  if (story["Name of Municipality"]) {
    storyTitle = story["Name of Municipality"];
  } else {
    storyTitle = "Unknown municipality";
  }

  let storyHeadersCSV = (story) => {
    const index = Object.entries(story);
    let storyHeadersArray = index.map((i) => {
      return {
        key: i[0],
        label: i[0],
      };
    });
    return storyHeadersArray;
  };

  let dataCSV = (story) => {
    let dataArray = [];
    const index = Object.entries(story);
    let obj = index.reduce((result, i) => {
      const key = i[0];
      const value = i[1];
      result[key] = [value];
      return result;
    }, {});
    dataArray.push(obj);
    return dataArray;
  };

  // Build an array of content to show in extra content element
  let storyContent = (story) => {
    let theContent = [];
    const skippedValues = [
      "Name of Municipality",
      "Type of Municipality",
      "Form of government (Charter or Non-Charter)",
      "Legislature",
    ];
    for (const property in story) {
      if (
        !skippedValues.includes(property) &&
        !property.includes("Tributary that Borders")
      ) {
        // rewrites for specific fields
        switch (property) {
          case "Constitutional and Statutory Lawmaking Provisions for Legislative Body":
          case "Statutory Lawmaking by Municipal Legislature":
          case "Initiative Lawmaking by People - Citation":
          case "Charter Lawmaking by Municipal Legislature - Citation":
            theContent.push(
              <div key={property + " div"}>
                <p key={property + " p"}>
                  <strong>{property}:</strong>
                </p>
                <pre key={property + " pre"}>{story[property]}</pre>
              </div>,
            );
            break;
          case "Source for Registered Voter Data":
          case "Source for Population Data":
            theContent.push(
              <p key={property}>
                <a href={story[property]} target="_blank" rel="noreferrer">
                  Source for Registered Voter Data
                </a>
              </p>,
            );
            break;
          case "Population of Municipality":
          case "Number of Registered Voters":
          case "Democratic Registered Voters":
          case "Republican Registered Voters":
          case "Other Registered Voters":
            theContent.push(
              <p key={property}>
                <strong>{property}:</strong>{" "}
                {story[property]
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>,
            );
            break;
          default:
            theContent.push(
              <p key={property}>
                <strong>{property}:</strong> {story[property]}
              </p>,
            );
        }
      }
    }
    return theContent;
  };

  return (
    <React.Fragment>
      <li className={storyClass} key={story.id} role="presentation">
        <div className="container">
          <img
            id="locateOnMap"
            onClick={() => props.onStoryClick(story)}
            src={pin}
            alt="location"
          ></img>
          {<h6 className="title">{storyTitle}</h6>}
          {story["Type of Municipality"] && (
            <p>
              <strong>Type of Municipality:</strong>{" "}
              {story["Type of Municipality"]}
            </p>
          )}
          {story["Form of government (Charter or Non-Charter)"] && (
            <p>
              <strong>Form of Government:</strong>{" "}
              {story["Form of government (Charter or Non-Charter)"]}
            </p>
          )}
          {story["Legislature"] && (
            <p>
              <strong>Legislature:</strong> {story["Legislature"]}
            </p>
          )}
          {story["Population of Municipality"] && (
            <p>
              <strong>Population:</strong>{" "}
              {popEstimate(story["Population of Municipality"])}
            </p>
          )}
          {story[
            "Tributaries that Border or Flow through the Municipality"
          ] && (
            <p>
              <strong>Tributaries:</strong> {tributaries()}
            </p>
          )}
          {story["Major Bay Water Sources"] && (
            <p>
              <strong>Water sources:</strong> {tributaries()}
            </p>
          )}
          {story["Initiative Lawmaking by People"] && (
            <p>
              <strong>Initiative Lawmaking by People:</strong>{" "}
              {distillLawmaking(story["Initiative Lawmaking by People"])}
            </p>
          )}
          {story["Charter Lawmaking by Municipal Legislature"] && (
            <p>
              <strong>Charter Lawmaking by Municipal Legislature:</strong>{" "}
              {distillLawmaking(
                story["Charter Lawmaking by Municipal Legislature"],
              )}
            </p>
          )}
          {story["Statutory Lawmaking by Municipal Legislature"] && (
            <p>
              <strong>Statutory Lawmaking by Municipal Legislature:</strong> ✔️
              Yes
            </p>
          )}
          {story["Democratic Registered Voters"] &&
            story["Republican Registered Voters"] && (
              <p>
                {compareDemRep(
                  story["Democratic Registered Voters"],
                  story["Republican Registered Voters"],
                )}
              </p>
            )}
          {storyContent(story).length !== 0 && (
            <button id="extraContentButton" onClick={buttonHandler}>
              {isExtraContentShown
                ? "Hide Additional Information"
                : "Additional Information"}
            </button>
          )}
          {isExtraContentShown && (
            <div id="extraContent">{storyContent(story)}</div>
          )}
          <p>
            {}
            <CSVLink
              data={dataCSV(story)}
              headers={storyHeadersCSV(story)}
              filename={"RON app - " + storyTitle + ".csv"}
            >
              <img src={csv} id="csv" alt="csv"></img>
            </CSVLink>
          </p>
        </div>
      </li>
    </React.Fragment>
  );
};

Story.defaultProps = {
  story: {},
  onStoryClick: () => {},
  storyClass: "",
};

export default Story;
